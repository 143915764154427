import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/CreateSpace",
    name: "CreateSpace",
    component: () => import("../components/CreateSpace/index.vue"),
  },
  {
    path: "/SpaceList",
    name: "SpaceList",
    component: () => import("../components/SpaceList/index.vue"),
  },
  {
    path: "/Test",
    name: "Test",
    component: () => import("../pages/Test.vue"),
  },
  // 测试
  {
    path: "/",
    name: "szy",
    component: () => import("../components/CreateSpace"),
  },
];

const router = new createRouter({
  history: createWebHistory('#'),
  routes,
});

export default router;
