<script setup>
import { ref, watch, onMounted, reactive, computed } from 'vue';
import { RouterLink, RouterView, useRoute } from 'vue-router';


const route = useRoute();
const routename = ref(route.name);
const pageList = [`LoginAuth`, 'ChainIDAuth', 'Modeledit', 'MiniProgramAuth'];

const showCommon = ref(true);

let setLock = null;
window.onresize = () => {
  return (() => {
    if (setLock) clearTimeout(setLock);
    setLock = setTimeout(() => {
      // store.windowWidth = document.documentElement.clientWidth;
      setLock = null;
    }, 300);
  })();
};
onMounted(() => {
  setShowHeader(route);
});
const setShowHeader = (val) => {
  if (pageList.indexOf(val.name) !== -1 && showCommon.value) {
    showCommon.value = false;
  } else if (pageList.indexOf(val.name) === -1 && !showCommon.value) {
    showCommon.value = true;
  }
};
const state = reactive({
  includeList: []
});
if (route.meta.keepAlive && state.includeList.indexOf(route.name) === -1) {
  state.includeList.push(route.name);
}
watch(() => route, (newVal, oldVal) => {
  if (newVal.meta.keepAlive && state.includeList.indexOf(newVal.name) === -1) {
    state.includeList.push(newVal.name);
  }
}, { deep: true });
const key = computed(() => {
  return route.fullPath;
});
</script>

<template>
  <div class="cont">
    <router-view v-slot="{ Component }">
      <keep-alive :include="state.includeList">
        <component :is="Component" v-if="$route.meta.keepAlive" :key="key" />
      </keep-alive>
      <component :is="Component" v-if="!$route.meta.keepAlive" :key="key" />
    </router-view>
    <!-- <RouterView /> -->
  </div>
</template>

<style scoped>
@media (min-width: 1024px) {}
</style>
